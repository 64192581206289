%box-shadow {
  background: #ffffff;
  box-shadow: 0px 0px 7px rgba(19, 216, 229, 0.3);
  border-radius: 5px;
}
.employee-management {
  &-input-group {
    width: 100%;
    margin-bottom: 50px;
    &-item {
      @extend %box-shadow;
    }
  }
  &-form label {
    font-size: 16px !important;
    font-weight: 500 !important;
  }
}
