.page-title {
  /* text-transform: uppercase; */
  font-weight: 500;
  padding: 0 10px;
  padding-bottom: 8px;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.75px solid #bbbcbd;
}

.ant-layout-header,
.ant-layout-footer {
  padding: 0 !important;
}
/* select */

/* custom select */
.ant-select-selector {
  /* box-shadow: 0px 8px 25px rgba(37, 107, 254, 0.15); */
  border-radius: 6px;
  overflow: hidden !important;
}
.dropdown-select-custom {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  /* box-shadow: 0px 0px 25px rgba(37, 107, 254, 0.29) !important; */
  overflow: hidden !important;
}

/* login checkbox */

.login-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #000;
  border-color: #000;
}

.login-checkbox .ant-checkbox-inner {
  background-color: transparent;
  border-color: #000 !important;
}

.login-checkbox:hover .ant-checkbox-inner,
.login-checkbox:hover .ant-checkbox-checked::after {
  border-color: #000 !important;
}

.login-checkbox .ant-checkbox:hover::after,
.login-checkbox .ant-checkbox-wrapper:hover .ant-checkbox::after {
  visibility: hidden !important;
}

.ant-dropdown {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(19, 216, 229, 0.2);
  border-radius: 5px;
}

.ant-card .ant-card-head-title {
  padding: 10px 0 10px 0;
}

.ant-row.ant-form-item {
  margin-bottom: 10px;
}

.ant-table-body::-webkit-scrollbar {
  height: 15px;
  width: 10px;
}
.ant-table-body::-webkit-scrollbar-track {
  background-color: #fff;
}
.ant-table-body::-webkit-scrollbar-thumb {
  background: #13d8e5;
  border-radius: 3px;
}

.ant-table-sticky-scroll {
  height: 7px !important;
  opacity: 1 !important;
}

.ant-table-sticky-scroll .ant-table-sticky-scroll-bar {
  background-color: #13d8e5;
  height: 15px;
}

/* custom pagination antd */
.anticon.anticon-left,
.anticon.anticon-right {
  color: #13d8e5 !important;
}

.ant-pagination-item {
  overflow: hidden !important;
  border-radius: 50% !important;
  border: 1px solid #13d8e5 !important;
  box-shadow: 0px 0px 25px rgba(0, 211, 224, 0.29);
  margin: 0 5px !important;
}

.ant-pagination-item-active a {
  color: white !important;
}

.ant-pagination-item-active {
  box-shadow: 0px 0px 25px rgba(37, 107, 254, 0.29) !important;
  overflow: hidden !important;
  border-radius: 50% !important;
  border: 0 !important;
  background-color: #13d8e5 !important;
}

.ant-pagination {
  margin-right: 30px !important;
}

.ant-layout-header,
.ant-layout-footer {
  background-color: transparent;
}
/* custom pagination antd */

/* custom upload antd */
.avatar-uploader {
  width: max-content;
}
.avatar-uploader > .ant-upload {
  width: 85px;
  height: 85px;
}

.bill-upload .ant-upload-select-picture-card {
  width: 60px !important;
  height: 60px !important;
}

.bill-upload .ant-upload-list-picture-card-container {
  width: 60px !important;
  height: 60px !important;
}

.bill-uploader > .ant-upload {
  width: 125px;
  height: 125px;
}
/* custom upload antd */
/* custom button antd */
.ant-btn {
  font-weight: 500 !important;
}

/* row table */
.bg-yellow {
  background-color: #fff38a;
}
.bg-orange .ant-table-cell {
  background-color: #fd9d9d !important;
}
.bg-blue {
  background-color: #e6fffc;
}

/* row table */

/* table */
.ant-table-container {
  border: 1px solid #d8d8d8;
  border-radius: 5px;
}
.ant-table .ant-table-small {
  margin: 0 !important;
}
/* table */

/* form item label  */
.ant-form-item-required {
  font-size: 16px !important;
  font-weight: 500 !important;
}
/* form item label  */

.ant-tabs-dropdown-menu-item {
  padding-top: 12px;
  padding-bottom: 12px;
}

.product-export-tabs .ant-tabs-nav-list {
  justify-content: space-between;
  width: 100%;
}

.ant-message {
  top: 50%;
}

.table-product-scan .ant-table {
  font-size: 16px;
}

.panel-bg-green {
  background-color: #55da6b;
}

.panel-bg-orange {
  background-color: #ffdb00;
}

.table-packages .ant-table-tbody > tr.ant-table-row:hover > td {
  background-color: unset;
}
.table-disable-hover .ant-table-tbody > tr.ant-table-row:hover > td {
  background-color: unset;
}

/* .table-menu .ant-table-tbody > tr > td {
  border-bottom: 0;
}

.table-menu .ant-table-cell {
  padding: 10px 0px !important;
} */

.btn-export {
  background-color: #79cf50 !important;
  border-color: #79cf50 !important;
}
