%boxshadow {
  background: #ffffff !important;
  box-shadow: 0px 2px 6px rgba(19, 216, 229, 0.3) !important;
  border-radius: 5px;
}
@mixin button-background($color) {
  min-width: 100px !important;
  background-color: $color !important;
  border-color: $color !important;
  color: white !important;
  &:hover {
    background-color: lighten($color, 8%) !important;
    border-color: lighten($color, 8%) !important;
  }
}
.customer {
  &-btn--create-customer {
      @include button-background(#377bff)
  }
  //Content section start here
  &-filter-group {
    margin-bottom: 20px;
  }
  &-btn-group {
    margin: 0 30px 20px 20px;
    &--delete-customer {
      @include button-background(#ff4d4f);
    }
    &--clear {
      @include button-background(#FF7089);
    }
  }
  &-filter-group-item {
    @extend %boxshadow;
  }
  &-filter-group-province {
    box-shadow: 0px 2px 6px rgba(19, 216, 229, 0.3);
  }
}

.title-input {
  div label {
    font-size: 16px;
    font-weight: 500;
  }
}

.dropdown div ul{
  width: 200px;
  height: 250px;
  overflow-y: scroll;
  overflow-x: hidden;
  &::-webkit-scrollbar {
      width: 6px;
      max-height: 6px;
  }
  &::-webkit-scrollbar-track {
      background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
      background: #02D0DD;
      border-radius: 25px;
  }
}

.modal {
  left: 120px;
  &-title {
    margin-bottom: 24px;
    text-align: center;
  }
  &-table {
    margin-bottom: 30px;
  }
  &-button-group {
    display: flex;
    justify-content: center;
    > * {
      margin-bottom: 0 !important;
    }
  }
  &-button {
    &--red {
      @include button-background(#ec0000);

    }
    &--orange {
      @include button-background(#EB7300);
    }
  }
}