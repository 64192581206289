@mixin button-background($color) {
  min-width: 100px !important;
  background-color: $color !important;
  border-color: $color !important;
  color: white !important;
  &:hover {
    background-color: lighten($color, 8%) !important;
    border-color: lighten($color, 8%) !important;
  }
}
.ingredient {
  &-content {
    margin-top: 25px;
  }
  &-button {
    &--orange {
      @include button-background(#eb7300);
    }
  }
  &-btn {
    &--create-ingredient {
        @include button-background(#377BFF)
      }
    &--print {
        @include button-background(#238800)
    }
  }
}

.title-input {
  > div > label {
    font-size: 16px;
    font-weight: 500;
  }
}
