@import 'assets/scss/variable';
@import 'assets/scss/global';
.Layout {
  margin: 20px;
  background: #fff;
  padding: 20px;
  min-height: calc(100vh - 150px);
  position: relative;
  .layout-header {
    padding-bottom: 15px;
    margin-bottom: 30px;
    border-bottom: solid 2px #e5e5e5;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .layout-title {
      font-size: 24px;
      font-weight: 500;
      max-width: 80%;
      width: max-content;
      display: flex;
      // justify-content: space-between;
      align-items: center;
      svg {
        padding-top: 10px;
        cursor: pointer;
        width: 30px;
        margin-right: 10px;
        display: inline-block;
      }
    }
  }
  &-modal [class='ant-modal-body'] {
    /* padding-top: 0; */
  }
  &-form label {
    font-size: 16px !important;
    font-weight: 500 !important;
  }
}

.layout-logo {
  background-color: #f0feff;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    height: 100px;
  }
}

.layout-header {
  display: flex;
  justify-content: space-between;
  padding: 0 50px 0 15px;
  height: 100%;
  background-color: #fff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  .expand-icon {
    cursor: pointer;
  }
}

.layout-container {
  margin: 1em;
  margin-top: 80px;
  padding: 1em;
  background-color: #fff;
  border-radius: 5px;
  height: 100%;
}
// rgb(245 249 255) #f0f2f5
.layout-user {
  border-radius: 15px !important;
  li {
    display: flex;
  }
  img {
    margin-right: 4px;
    width: 23px;
    aspect-ratio: 1;
    padding: 4px;
    object-fit: contain;
    border-radius: 100rem;
    background-color: $blue2;
  }
}
.layout-noti-empty {
  &-card {
    padding: 20px;
    border-radius: 5px !important;
  }
}
.layout-noti-list {
  border-radius: 15px !important;
  padding: 15px !important;
  min-width: 300px !important;

  &-wrapper {
    max-width: 353px;
    max-height: 320px;

    overflow-y: scroll;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-track {
      background-color: #fff;
    }
    &::-webkit-scrollbar-thumb {
      background: $blue1;
      border-radius: 25px;
    }
  }
}
.side-menu {
  &::-webkit-scrollbar {
    width: 8.5px;
  }
  &::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 5px;
    &:hover {
      background: #a8a8a8;
    }
  }
}

.user_information {
  //padding: 10px 40px;
  width: 250px;
  border: 1px solid rgb(230, 218, 218);
  border-radius: 15px;
  color: #565656;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.user_information_link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 0.75rem 0;
  color: #565656;
  width: 100%;
}
