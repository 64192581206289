@mixin button-background($color) {
  min-width: 100px !important;
  background-color: $color !important;
  border-color: $color !important;
  color: white !important;
  &:hover {
    background-color: lighten($color, 8%) !important;
    border-color: lighten($color, 8%) !important;
  }
}
.printer-management {
  &-button {
    &--orange {
      @include button-background(#eb7300);
    }
    &--blue {
      @include button-background(#377bff);
    }
    &--green {
      @include button-background(#79cf4f);
    }
    &--pink {
      @include button-background(rgba(236, 0, 0, 0.5));
    }
  }
}
