@mixin button-background($color) {
  min-width: 100px !important;
  background-color: $color !important;
  border-color: $color !important;
  color: white !important;
  &:hover {
    background-color: lighten($color, 8%) !important;
    border-color: lighten($color, 8%) !important;
  }
}
@mixin row-table-background($color) {
  background-color: $color !important;
  [class='ant-table-cell ant-table-column-sort'] {
    background-color: $color;
  }
  & > td {
    background-color: $color !important;
    border-color: white !important;
  }
  &:hover {
    & > td {
      background-color: darken($color, 5%) !important;
    }
  }
}
.importProduct {
  &-content {
    margin-top: 20px;
    &--tab {
      border: 1px solid #02d0dd;
      border-radius: 5px;
      padding: 10px;
      font-size: 16px;
      // font-weight: 500;
      line-height: 19px;
      right: 0 !important;
      top: 50px !important;
    }
  }
}
.ant-list {
  height: 405px;
  overflow-y: scroll;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 6px;
    max-height: 6px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: #02d0dd;
    border-radius: 25px;
  }
}
.print-template {
}

.btn {
  &-report {
    @include button-background(#eb7300);
  }
  &-scan {
    @include button-background(#238800);
  }
}

.PROCESSING {
  @include row-table-background(#80e9ef);
}
.WAITING {
  @include row-table-background(#ffce62);
}
.COMPLETE {
  @include row-table-background(#79cf4f);
}

.REPORT {
  @include row-table-background(#d63f40);
}
