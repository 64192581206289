@mixin button-background($color) {
  min-width: 100px !important;
  background-color: $color !important;
  border-color: $color !important;
  color: white !important;
  &:hover {
    background-color: lighten($color, 8%) !important;
    border-color: lighten($color, 8%) !important;
  }
}
@mixin list-item-background($color) {
  background-color: $color !important;
  &:hover {
    & > td {
      background-color: lighten($color, 15%) !important;
    }
  }
}
@mixin list-item() {
  padding: 5px 15px;
  border-radius: 5px;
  border: 1px solid #13d8e5 !important;
  font-size: 16px;
  line-height: 22px;
  color: black !important;
}
.product-import {
  &-content {
    width: 100%;
    padding: 10px;
    border: 1px solid #02d0dd;
    background-color: #fff;
    box-shadow: 0px 0px 4px rgba(19, 216, 229, 0.25);
    border-radius: 5px;
  }
  &-button {
    &--blue {
      @include button-background(#377bff);
    }
    &--lightblue {
      @include button-background(#6caffe);
    }
    &--green {
      @include button-background(#79cf4f);
    }
  }
}
.btn-clear {
  @include button-background(#ff7089);
}
.WAITING {
  @include list-item-background(#ffce62);
  @include list-item();
}
.PROCESSING {
  background-color: rgb(19 216 229) !important;
  &:hover {
    & > td {
      background-color: rgb(19 216 229) !important;
    }
  }
  @include list-item();
}
.COMPLETE {
  @include list-item-background(#79cf4f);
  @include list-item();
}
.REPORT {
  @include list-item-background(#d63f3f);
  @include list-item();
}

.text-bill div {
  i {
    font-size: 15px;
  }
  b {
    font-size: 17px;
  }
}

.text-sign div b {
  font-size: 12px;
  width: 100%;
  text-align: center;
}

.table-product {
  div div div div {
    border-color: black !important;
    border-radius: 0 !important;
  }
  table {
    border-color: black !important;
    border-radius: 0 !important;
    th {
      border-color: black !important;
      font-size: 14px;
      font-weight: bold !important;
      border-radius: 0 !important;
    }
    td {
      border-color: black !important;
      font-size: 14px;
      border-radius: 0 !important;
    }
  }
}

@media print {
  header {
    page-break-before: always;
  }
  footer {
    page-break-before: always;
  }
}

.btn-print {
  @include button-background(#03a6a6)
}