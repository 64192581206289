@mixin button-background($color) {
  min-width: 100px !important;
  background-color: $color !important;
  border-color: $color !important;
  color: white !important;
  &:hover {
    background-color: lighten($color, 8%) !important;
    border-color: lighten($color, 8%) !important;
  }
}
.transported-transfer {
  &-content {
    width: 100%;
    padding: 10px !important;
    border: 1px solid #02d0dd;
    background-color: #fff;
    box-shadow: 0px 0px 4px rgba(19, 216, 229, 0.25);
    border-radius: 5px;
  }
  &-button {
    &--orange {
      @include button-background(#eb7300);
    }
    &--green {
      @include button-background(#79cf4f);
    }
    &--pink {
      @include button-background(#ff7089);
    }
    &--green1 {
      @include button-background(green);
    }
    &--darkred {
      @include button-background(#d63f3f);
    }
    &--active {
      color: white !important;
    }
  }
  &-table-expand {
    @extend .transported-transfer-content;
    padding-top: 10px !important;
  }

  &-form label {
    font-size: 16px !important;
    font-weight: 500 !important;
  }
  &-shipping-company {
    min-height: 130px;
    padding: 10px 20px;
  }
  &-personal-info {
    @extend .transported-transfer-shipping-company;
    border-radius: 3px;
    transition: 0.3s;
    &:hover {
      background-color: #f3f3f3;
    }
  }

  &-upload-button [class='ant-upload ant-upload-select ant-upload-select-picture-card'] {
    width: 100% !important;
    height: 100% !important;
    [class='ant-upload'][role='button'] {
      width: 100%;
      min-height: 300px;
      display: flex;
      align-items: center;
    }
  }
}
@media print {
  footer {
    page-break-before: always;
  }
}
.print-template {
  [class='ant-table-container'] {
    border: 1px solid black !important;
  }
  [class='ant-table-cell'] {
    border: 1px solid black !important;
  }
}
