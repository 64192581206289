.login{
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    position: relative;
    // background: url('https://admin-order.s3.ap-northeast-1.wasabisys.com/2021/11/03/3eb89c6b-8d3c-4fed-b4b8-e7e5aefb7888/627%20%5BConverted%5D-01%201.png') no-repeat center center fixed; 
    // -webkit-background-size: cover;
    // -moz-background-size: cover;
    // -o-background-size: cover;
    // background-size: cover;
    img{
        width: 100vw;
        height: 100vh;
    }

    &-form{
        width: 350px;
        position: absolute;
        top: 100px;
        left: 100px;

        h2{
            font-weight: 700;
        }
        
        
        

        .login-button{
            background-color: black;
            color: #fff;
            border: none;
            transition: 0.3s;
            width: 100%;
            border-radius: 5px;
            &:hover{
                background-color: lighten($color: #000000, $amount: 5);
            }
        }
    }

    &-footer{
        position: absolute;
        bottom: 20px;
        left: 35px;
        font-size: 16px;
        font-weight: 700;
    }
}