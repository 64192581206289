@mixin button-background($color) {
  min-width: 100px !important;
  background-color: $color !important;
  border-color: $color !important;
  color: white !important;
  &:hover {
    background-color: lighten($color, 8%) !important;
    border-color: lighten($color, 8%) !important;
  }
}
@mixin list-item-background($color) {
  background-color: $color;
  &:hover {
    & > td {
      background-color: lighten($color, 8%) !important;
    }
  }
}
@mixin list-item() {
  padding: 5px 15px;
    border-radius: 5px;
    border: 1px solid #13d8e5 !important;
    font-size: 16px;
    line-height: 22px;
    color: black !important;
}
.product-import {
  &-content {
    width: 100%;
    padding: 10px;
    border: 1px solid #02d0dd;
    background-color: #fff;
    box-shadow: 0px 0px 4px rgba(19, 216, 229, 0.25);
    border-radius: 5px;
  }
  &-button {
    &--lightblue {
      @include button-background(#6caffe);
    }
    &--green {
      @include button-background(#79cf4f);
    }
  }
}
.btn-clear {
  @include button-background(#FF7089)
}
.WAITING {
  @include list-item-background(#ffce62);
  @include list-item();
}
.PROCESSING {
  @include list-item-background(#13d8e5);
  @include list-item();
}
.COMPLETE {
  @include list-item-background(#79cf4f);
  @include list-item();
}
.REPORT {
  @include list-item-background(#d63f3f);
  @include list-item();
}
