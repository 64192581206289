.card-statistical {
  background-color: #e0f9fc;
  border: 2px solid #82e7f4;
  border-radius: 5px;
  width: 27%;
  height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
