.global-text {
  font-size: 16px;
  line-height: 19px;
}
.global-box-shadow {
  background: #ffffff !important;
  box-shadow: 0px 2px 6px rgba(19, 216, 229, 0.3) !important;
  border-radius: 5px;
}
.button {
  border-radius: 5px;
  &--orange {
    background-color: #ffa979;
  }
  &--pink {
    background-color: #ff7089;
  }
}
