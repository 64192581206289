.reportInventory {
  &-content {
    margin-top: 25px;
    .select-search {
      > div {
        border-radius: 0 5px 5px 0 !important;
      }
    }
    &--total {
      text-align: center;
      background: rgba(130, 231, 244, 0.25);
      border-radius: 5px;
      border: 2px solid #82e7f4;
      padding: 10px 0;
      margin: 0 40px 10px 40px;
      font-size: 16px;
      span {
        font-weight: 500;
      }
    }
    &--chart {
      height: 240px;
      background-color: #fdfdff;
      border: 1px solid #ebebeb;
      border-radius: 13px;
      padding: 10px 45px;
      margin: 0 40px;
      canvas {
        height: 100% !important;
      }
    }
    &--inventory {
      text-align: center;
      background: rgba(130, 231, 244, 0.25);
      border-radius: 5px;
      border: 2px solid #82e7f4;
      margin: 0 40px;
      padding: 20px 0;
      font-size: 16px;
      span {
        font-weight: 500;
      }
    }
  }
  &-table {
    padding: 0 10px;
    div > div > div > div > div {
      &::-webkit-scrollbar {
        width: 6px;
      }
      &::-webkit-scrollbar-track {
        background-color: transparent;
      }
      &::-webkit-scrollbar-thumb {
        background: #02d0dd;
        border-radius: 25px;
      }
    }
    tbody tr[class='ant-table-row ant-table-row-level-1'] td > label {
      display: none;
    }
  }
}

.title-statistical {
  font-size: 16px;
  font-weight: 500;
  margin: 0 0 20px 20px;
}

.title-inventory {
  font-size: 16px;
  font-weight: 500;
  margin: 0 40px 0 20px;
}
