@mixin button-background($color) {
  min-width: 100px !important;
  background-color: $color !important;
  border-color: $color !important;
  color: white !important;
  &:hover {
    background-color: lighten($color, 8%) !important;
    border-color: lighten($color, 8%) !important;
  }
}
@mixin row-table-background($color) {
  background-color: $color !important;
  [class='ant-table-cell ant-table-column-sort'] {
    background-color: $color;
  }
  & > td {
    background-color: $color !important;
    border-color: white !important;
  }
  &:hover {
    & > td {
      background-color: darken($color, 5%) !important;
    }
  }
}
.pack {
  .complete {
    @include row-table-background(#79cf4f);
  }
  .waiting {
    @include row-table-background(#ffce62);
  }
  .processing {
    @include row-table-background(#80e9ef);
  }
  .report {
    @include row-table-background(#d63f40);
  }
}
.pack-modal [class='ant-modal-header'] {
  background: #13d8e5 !important;
  font-weight: 700;
  text-align: center;
}
.btn {
  &-clear {
    @include button-background(#FF7089)
  }
  &-list {
    @include button-background(#238800)
  }
  &-scan {
    @include button-background(#EB7301)
  }
  &-confirm {
    @include button-background(#a8b11e)
  }
}

.btn-print {
  @include button-background(#03a6a6)
}

.table-product {
  div div div div {
    border-color: black !important;
    border-radius: 0 !important;
  }
  table {
    border-color: black !important;
    border-radius: 0 !important;
    th {
      border-color: black !important;
      font-size: 12px;
      font-weight: bold !important;
      border-radius: 0 !important;
    }
    td {
      border-color: black !important;
      font-size: 12px;
      border-radius: 0 !important;
    }
  }
}

@media print {
  header {
    page-break-before: always;
  }
  footer {
    page-break-before: always;
  }
}