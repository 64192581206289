.warehouse-add{
    height: 100%;
    .title{ 
        border-bottom: solid 1px #BBBCBD; 
        padding-bottom: 10px;
        a{
            font-size: 16px;
            font-weight: 500;
            color: #000 !important;
        }
    }
    &-body{
        margin-top: 20px;
        label{
            font-weight: 500;
        }
        &-btn--save {
            background-color: #FF9C64 !important;
            border-color: #FF9C64 !important;
            color: white !important;
            min-width: 100px !important;
            &:hover {
                background-color: lighten(#FF9C64, 8%) !important;
            }
        }
    }
}
