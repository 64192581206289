@mixin button-background($color) {
  min-width: 100px !important;
  background-color: $color !important;
  border-color: $color !important;
  color: white !important;
  &:hover {
    background-color: lighten($color, 8%) !important;
    border-color: lighten($color, 8%) !important;
  }
}
@mixin row-table-background($color) {
  background-color: $color !important;
  [class='ant-table-cell ant-table-column-sort'] {
    background-color: $color;
  }
  & > td {
    background-color: $color !important;
    border-color: white !important;
  }
  &:hover {
    & > td {
      background-color: darken($color, 5%) !important;
    }
  }
}
//Tạm ẩn màu
/* .WAITING {
  @include row-table-background(#ffce62);
}
.PROCESSING {
  @include row-table-background(#80e9ef);
}
.COMPLETE {
  @include row-table-background(#79cf4f);
}
.REPORT {
  @include row-table-background(#d63f3f);
} */
.transport-transfer {
  &-content {
    width: 100%;
    padding: 10px !important;
    border: 1px solid #02d0dd;
    background-color: #fff;
    box-shadow: 0px 0px 4px rgba(19, 216, 229, 0.25);
    border-radius: 5px;
  }
  &-button {
    &--orange {
      @include button-background(#eb7300);
    }
    &--green {
      @include button-background(#79cf4f);
    }
    &--pink {
      @include button-background(#ff7089);
    }
    &--green1 {
      @include button-background(green);
    }
    &--darkred {
      @include button-background(#d63f3f);
    }
    &--active {
      color: white !important;
    }
  }
  &-table-expand {
    @extend .transport-transfer-content;
    padding-top: 10px !important;
  }

  &-form label {
    font-size: 16px !important;
    font-weight: 500 !important;
  }
  &-shipping-company {
    min-height: 130px;
    padding: 10px 20px;
  }
  &-personal-info {
    @extend .transport-transfer-shipping-company;
    border-radius: 3px;
    transition: 0.3s;
    &:hover {
      background-color: #f3f3f3;
    }
  }

  &-upload-button [class='ant-upload ant-upload-select ant-upload-select-picture-card'] {
    width: 100% !important;
    height: 100% !important;
    [class='ant-upload'][role='button'] {
      width: 100%;
      /*  min-height: 40px; */
      max-height: 40px;
      display: flex;
      align-items: center;
    }
  }
}
@media print {
  footer {
    page-break-before: always;
  }
}
.shipping-company-form-item [class='ant-col ant-form-item-label'] {
  padding-bottom: 0;
}
/* .print-template {
  [class='ant-table-container'] {
    border: 0.01em solid black !important;
  }
  [class='ant-table-cell'] {
    border: 0.01em solid black !important;
  }
} */

.table-product {
  div div div div {
    border-color: black !important;
    border-radius: 0 !important;
  }
  table {
    border-color: black !important;
    border-radius: 0 !important;
    th {
      border-color: black !important;
      font-size: 12px;
      font-weight: bold !important;
      border-radius: 0 !important;
    }
    td {
      border-color: black !important;
      font-size: 12px;
      border-radius: 0 !important;
    }
  }
}
