@import "assets/scss/variable";
@import "assets/scss/global";
@mixin button-background($color) {
  min-width: 100px !important;
  background-color: $color !important;
  border-color: $color !important;
  color: white !important;
  &:hover {
    background-color: lighten($color, 8%) !important;
    border-color: lighten($color, 8%) !important;
  }
}
.setting-box {
  //Header section start here
  &-btn--create-basket {
      @include button-background(#377BFF);
  }
  &-search {
    width: 300px !important;
    margin-left: 20px;
    margin-bottom: 34px;
    @extend .global-box-shadow;
  }

  &-button-group {
    display: flex;
    margin-bottom: 13px;
    button {
      display: flex;
      align-items: center;
      img {
        margin-right: 10px;
      }
    }
  }
  &-button-group-left {
    display: flex;
    gap: 20px;
    margin-left: 31px;
    &--delete {
      @include button-background(#ec0000);
    }
    &--update {
      @include button-background(#00D3E0);
    }
    &--barcode {
      @include button-background(#238800);
    }
  }
  &-button-group-right {
    display: flex;
    gap: 20px;
    margin-left: auto;
    margin-right: 31px;
    &--column-config {
      @include button-background(#ffa979);
    }
    &--remove-filter {
      @include button-background(#ff7089);

    }
  }
  &-btn-update {
    @include button-background(#00D3E0);
  }
}

.modal {
  &-title {
    margin-bottom: 24px;
    text-align: center;
  }
  &-form {
    margin-bottom: 23px;
  }
  button {
    margin: 0 15px;
    img {
      margin-right: 10px;
    }
  }
  &-button-group {
    display: flex;
    justify-content: center;
    > * {
      margin-bottom: 0 !important;
    }
  }
  &-button {
    &--red {
      @include button-background(#ec0000);

    }
    &--orange {
      @include button-background(#EB7300);
    }
  }
  label {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
  }
}

