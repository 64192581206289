@mixin button-background($color) {
  min-width: 100px !important;
  background-color: $color !important;
  border-color: $color !important;
  color: white !important;
  &:hover {
    background-color: lighten($color, 8%) !important;
    border-color: lighten($color, 8%) !important;
  }
}
.product-check-scan {
  &-button {
    &--orange {
      @include button-background(#EB7300);
    }
  }
  &-position {
    width: 100%;
    height: 450px;
    background-color: #ccc;
    margin: 0 30px;
    cursor: pointer;
  }
  &-btn--scan {
    @include button-background(#EB7300);
  }
}
.modal {
  &-title {
    padding: 20px 0;
    text-align: center;
    background: rgba(255, 0, 0, 0.5);
  }
  &-content {
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: #000000;
    margin: 40px 50px 0 50px;
  }
  &-button-group {
    margin-top: 40px;
    display: flex;
    justify-content: flex-end;
    gap: 20px;
  }
}
