@mixin button-background($color) {
  min-width: 100px !important;
  background-color: $color !important;
  border-color: $color !important;
  color: white !important;
  &:hover {
    background-color: lighten($color, 8%) !important;
    border-color: lighten($color, 8%) !important;
  }
}

.warehouse {
  &-btn-create {
    @include button-background(#377BFF);
  }
  &-group-btn {
    margin-top: 20px;
    margin-bottom: 20px;
    &--remove-filter {
      @include button-background(#FF7089);
    }
  }
  &-table {
    &-btn--create-shelve {
      @include button-background(#0088EB);
    }
  }
}

.table-child {
    > div > div {
        margin: 5px 20px 5px 20px;
        >div {
            margin: 0 !important;
            > div {
                border: 1px solid #d8d8d8 !important;
            }
        }
    }
}
.dropdown div ul{
  width: 200px;
  height: 250px;
  overflow-y: scroll;
  overflow-x: hidden;
  &::-webkit-scrollbar {
      width: 6px;
      max-height: 6px;
  }
  &::-webkit-scrollbar-track {
      background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
      background: #02D0DD;
      border-radius: 25px;
  }
}

.modal-delete {
  &-title {
    padding: 20px 0;
    text-align: center;
    background: rgba(255, 0, 0, 0.5);
  }
  &-desc {
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: #000000;
    margin-bottom: 40px;
  }
  &-button-group {
    text-align: center;
    &--cancel {
        padding: 0 35px;
    }
    &--ok {
        padding: 0 20px;
    }
  }
}