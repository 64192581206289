@mixin button-background($color) {
  min-width: 100px !important;
  background-color: $color !important;
  border-color: $color !important;
  color: white !important;
  &:hover {
    background-color: lighten($color, 8%) !important;
    border-color: lighten($color, 8%) !important;
  }
}

.btn {
  &-print {
    @include button-background(#03a6a6);
  }
  &-pakage {
    @include button-background(#238800);
  }
  &-confirm {
    @include button-background(#a8b11e);
  }
}

.table-product {
  div div div div {
    border-color: black !important;
    border-radius: 0 !important;
  }
  table {
    border-color: black !important;
    border-radius: 0 !important;
    th {
      border-color: black !important;
      font-size: 12px;
      font-weight: bold !important;
      border-radius: 0 !important;
    }
    td {
      border-color: black !important;
      font-size: 12px;
      border-radius: 0 !important;
    }
  }
}

.input-barcode-product {
  width: 100%;
  padding: 5px;
  font-size: 16px;
}

@media print {
  footer {
    page-break-before: always;
  }
}
