@mixin button-background($color) {
  min-width: 100px !important;
  background-color: $color !important;
  border-color: $color !important;
  color: white !important;
  &:hover {
    background-color: lighten($color, 8%) !important;
    border-color: lighten($color, 8%) !important;
  }
}
$boxShadow: '0px 2px 6px rgba(19, 216, 229, 0.13)';
.activityhistory {
  &-content {
    margin-top: 15px;
    &-input--search,
    &-select--time,
    &-select--type,
    &-select--properties {
      box-shadow: '0px 2px 6px rgba(19, 216, 229, 0.13)' !important;
    }
  }
  &-button {
    &--deletefilter {
    }
  }
}

.dot-note {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-right: 5px;
}

.dot-note-green {
  background-color: green;
}

.dot-note-black {
  background-color: black;
}

.data-new {
  color: green;
}

.data-old {
  color: black;
}
