@mixin button-background($color) {
  min-width: 100px !important;
  background-color: $color !important;
  border-color: $color !important;
  color: white !important;
  &:hover {
    background-color: lighten($color, 8%) !important;
    border-color: lighten($color, 8%) !important;
  }
}

.shipping-company {
  &-content {
    margin-top: 15px;
  }
  &-button {
    &--orange {
      @include button-background(#eb7300);
    }
    &--blue {
      @include button-background(#377bff);
    }
  }
}

.item-avatar > div > label{
  font-size: 16px;
  font-weight: 600;
}
